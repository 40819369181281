<template>
  <v-app class="fill-height expand-cancellation guest-page">
    <EnvironmentIndicator />
    <div class="guest-header">
      <div class="guest-header__inner container">
        <div v-if="!getLogoUrl()" class="guest-header__name">
          {{ op(reservation, 'company/name') }}
        </div>
        <div v-if="getLogoUrl()" class="guest-header__view">
          <div
            class="guest-header__logo"
            :style="`background-image:url(${getLogoUrl()})`"
          />
        </div>
      </div>
    </div>
    <v-form
      v-if="isCancellationConfirmed === false"
      ref="customer-form"
      :lazy-validation="true"
    >
      <v-layout row wrap sheet>
        <v-flex xs12 text-xs-center>
          <br />
          <h1 class="cancel-reservation-title">Cancel Reservation</h1>
        </v-flex>

        <v-flex xs4 />
        <v-flex xs4 text-xs-center>
          <p>
            Please provide us with the following so that we may confirm your
            trip information and complete your cancellation. Card information is
            necessary in order to confirm your identity and issue your refund,
            if applicable.
          </p>
        </v-flex>
        <v-flex xs4 />

        <v-flex xs4 />
        <v-flex xs4 text-xs-center>
          <p>
            Again, we want to thank you for working with
            {{ op(reservation, 'companyName') }}. We hope to see you again for
            your future travels!
          </p>
          <br />
          <br />
        </v-flex>
        <v-flex xs4 />

        <v-flex xs4 />
        <v-flex xs4>
          <CRInput
            v-model="name"
            label="Your Name"
            :rules="
              confirmCancellationIndicator
                ? [
                    isRequired(true, isNotEmpty, {
                      req: 'Your Name Is Required',
                      error: 'Your Name Is Required',
                    }),
                  ]
                : []
            "
          />
        </v-flex>
        <v-flex xs4 />

        <v-flex xs4 />
        <v-flex xs4>
          <CRInput
            v-model="relationship"
            label="Relationship to Cardholder"
            :rules="
              confirmCancellationIndicator
                ? [
                    isRequired(true, isNotEmpty, {
                      req: 'Relationship to Cardholder Is Required',
                      error: 'Relationship to Cardholder Is Required',
                    }),
                  ]
                : []
            "
          />
        </v-flex>
        <v-flex xs4 />

        <v-flex xs4 />
        <v-flex xs4>
          <CRInput
            v-model="cardholderName"
            label="Cardholder Name"
            :rules="
              confirmCancellationIndicator
                ? [
                    isRequired(true, isNotEmpty, {
                      req: 'Cardholder Name Is Required',
                      error: 'Cardholder Name Is Required',
                    }),
                  ]
                : []
            "
          />
        </v-flex>
        <v-flex xs4 />

        <v-flex xs4 />
        <v-flex xs4>
          <CRInput
            v-model="lastFour"
            label="Last four digits of credit card or checking account number"
            :rules="
              confirmCancellationIndicator
                ? [
                    isRequired(true, isNotEmpty, {
                      req: 'This Information Is Required',
                      error: 'This Information Is Required',
                    }),
                    isRequired(true, isNumber, {
                      req: 'Value Must Be Valid Number',
                      error: 'Value Must Be Valid Number',
                    }),
                  ]
                : []
            "
            type="text"
            maxlength="4"
          />
        </v-flex>
        <v-flex xs4 />

        <v-flex xs4 />
        <v-flex xs4>
          <label>
            Were you the person who booked the reservation and agreed to the
            terms on {{ createdOnDate }}?
          </label>
          <v-radio-group v-model="bookedByMe" row>
            <v-radio value="yes" label="Yes" />
            <v-radio value="no" label="No" />
          </v-radio-group>
        </v-flex>
        <v-flex xs4 />

        <v-flex v-if="bookedByMe === 'no'" xs4 />
        <v-flex v-if="bookedByMe === 'no'" xs4 text-xs-center>
          <CRInput
            v-model="whoBooked"
            label="Who booked the reservation?"
            :rules="
              bookedByMe === 'no' && confirmCancellationIndicator
                ? [
                    isRequired(true, isNotEmpty, {
                      req: 'This Information Is Required',
                      error: 'This Information Is Required',
                    }),
                  ]
                : []
            "
          />
        </v-flex>
        <v-flex v-if="bookedByMe === 'no'" xs4 />

        <v-flex xs4 />
        <v-flex xs4 text-xs-center>
          <v-checkbox
            v-model="confirmCancellationIndicator"
            label="Are you sure you want to cancel your reservation?"
          />
        </v-flex>
        <v-flex xs4 />

        <v-flex xs4 />
        <v-flex xs4 text-xs-center>
          <v-btn
            class="btn-primaryaction"
            :disabled="!confirmCancellationIndicator"
            @click="confirmCancellation"
          >
            Cancel Reservation
          </v-btn>
        </v-flex>
        <v-flex xs4 />
      </v-layout>
    </v-form>
    <v-layout v-if="isCancellationConfirmed === true" row wrap sheet>
      <v-flex xs12 text-xs-center>
        <br />
        <h1 class="cancel-reservation-title">Cancellation Started</h1>
        Again, we want to thank you for working with
        {{ op(reservation, 'companyName') }}. We hope to see you again for your
        future travels!
      </v-flex>
    </v-layout>
  </v-app>
</template>
<script>
import op from 'simple-object-path'
import { DateTime } from 'luxon'
import {
  isNotEmpty,
  isRequired,
  validateEmail,
  isNumber,
} from '@/utils/validators'
import EnvironmentIndicator from '@/components/EnvironmentIndicator.vue'
export default {
  components: { EnvironmentIndicator },
  data() {
    return {
      op,
      isNotEmpty,
      isRequired,
      validateEmail,
      isNumber,
      lastFour: undefined,
      cardholderName: undefined,
      name: undefined,
      relationship: undefined,
      reservation: undefined,
      whoBooked: undefined,
      bookedByMe: 'yes',
      confirmCancellationIndicator: false,
      isCancellationConfirmed: undefined,
    }
  },
  computed: {
    createdOnDate() {
      return DateTime.fromISO(this.reservation?.createdOn).toFormat(
        'MM/dd/yyyy'
      )
    },
  },
  async mounted() {
    const hash = this.$route.params.hash
    const reservationData = await this.$store
      .dispatch('reservations/simpleReservations', hash)
      .catch((e) => false)
    this.reservation = reservationData?.data
    const cancellationTicketStatus =
      reservationData?.data?.cancellationTicketStatus || ''
    this.isCancellationConfirmed =
      cancellationTicketStatus.toUpperCase() !== 'CREATED'
  },
  methods: {
    getLogoUrl() {
      const lightLogoUrl = this.reservation?.companyLightLogoUrl
      if (lightLogoUrl) {
        return lightLogoUrl
      }
      return null
    },
    async confirmCancellation() {
      const isValid = this.$refs['customer-form'].validate()
      if (!isValid) {
        return
      }
      const { cancellationTicketId: ticketId, reservationId } = this.reservation
      const comments = this.stringifyForm()
      const payload = {
        ticketId,
        comments,
      }
      const params = {
        reservationId,
        payload,
      }
      await this.$store
        .dispatch('reservations/confirmCancellation', params)
        .catch((e) => false)
      this.isCancellationConfirmed = true
      this.$forceUpdate()
    },
    stringifyForm() {
      return `Name: ${this.name}
Relationship to cardholder: ${this.relationship}
Cardholder Name: ${this.cardholderName}
Last four digits of credit card or checking account number: ${this.lastFour}
Were you the person who booked the reservation and agreed to the terms?: ${
        this.bookedByMe === 'yes' ? 'Yes' : 'No'
      }
Who booked the reservation (if previous answer was 'no')?: ${this.whoBooked}`
    },
  },
}
</script>
<style lang="scss">
.cancel-reservation-title {
  padding-top: 20px;
}

.guest-header {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: $white;
  background: $blue-darker;
  background-image: linear-gradient(to right, $blue-darker, $blue-dark);

  &__inner {
    flex: 1 1 auto;
    max-height: 100px;
    padding: 15 30;
    background-image: url('~@/assets/images/map.svg');
    background-repeat: no-repeat;
    background-position: center -125px;
    background-size: cover;
  }

  &__name {
    padding: 15 0;
    font-size: 30px;
  }

  &__view {
    width: 180px;
  }

  &__logo {
    width: 100%;
    padding-top: 25%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
}

.expand-cancellation {
  background-color: $white;

  &__body {
    max-width: 400px;
  }

  &__description {
    text-align: center;

    & + & {
      margin-top: 30;
    }
  }

  &__form {
    max-width: 400px;
    padding: 0 15;
    margin: (30 * 1.5) auto 0;

    .form-group + .form-group {
      margin-top: 15 * 1.5;
    }
  }

  &__radios {
    margin-top: 10;

    > * {
      margin-left: 30;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 30 * 2;
  }
}
</style>
